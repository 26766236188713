<template>
  <v-container fluid>
    <v-dialog v-model="dialogUpload.show" width="700">
      <v-card>
          <v-toolbar color="primary" dark>Upload Images for {{dialogUpload.record.vehicleVin}}</v-toolbar>
          <v-card-text>
              <div class="pa-12">
                 <UploadFile v-if="dialogUpload.record" :record="dialogUpload.record" />
              </div>
          </v-card-text>
          <v-card-actions class="justify-end">
              <v-btn text @click="dialogUpload.show = false">Close</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRecord.show" persistent :overlay="false" max-width="600px">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-card-title class="title primary white--text mb-4 text-capitalize">
            Request Details
            <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              @click="dialogRecord.show = false;"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-simple-table dense>
                <tbody>
                  <tr>
                    <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                      <strong>Contact Info</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Name</td>
                    <td class="px-0 pr-1">{{ dialogRecord.record.firstName }} {{ dialogRecord.record.lastName }}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Email</td>
                    <td class="px-0 pr-1"><a :href="'mailto:' + dialogRecord.record.email">{{ dialogRecord.record.email }}</a></td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Phone</td>
                    <td class="px-0 pr-1"><a :href="'tel:' + dialogRecord.record.phone">{{ dialogRecord.record.phone }}</a></td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Street</td>
                    <td class="px-0 pr-1">{{ dialogRecord.record.streetAddress }}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">City/State</td>
                    <td class="px-0 pr-1">{{ dialogRecord.record.city }}, {{ dialogRecord.record.state }}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Zip</td>
                    <td class="px-0 pr-1">{{ dialogRecord.record.zip }}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Full Address</td>
                    <td class="px-0 pr-1">
                      {{ dialogRecord.record.firstName }} {{ dialogRecord.record.lastName }}<br/>
                      {{ dialogRecord.record.streetAddress }}<br/>
                      {{ dialogRecord.record.city }}, {{ dialogRecord.record.state }} {{ dialogRecord.record.zip }}
                      <div
                        class="primary--text"
                        style="cursor: pointer;
                        text-decoration: underline;"
                        @click="copyText(
                        `${dialogRecord.record.firstName} ${dialogRecord.record.lastName}\r\n${dialogRecord.record.streetAddress}\r\n${dialogRecord.record.city}, ${dialogRecord.record.state} ${dialogRecord.record.zip}`
                      )">Copy to Clipboard</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                      <strong>Vehicle Info</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Type</td>
                    <td class="px-0 pr-1">{{dialogRecord.record.vehicleType}}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Year</td>
                    <td class="px-0 pr-1">{{dialogRecord.record.vehicleYear}}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Make</td>
                    <td class="px-0 pr-1">{{dialogRecord.record.vehicleMake}}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Model</td>
                    <td class="px-0 pr-1">{{dialogRecord.record.vehicleModel}}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">VIN</td>
                    <td class="px-0 pr-1">{{dialogRecord.record.vehicleVin}}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Odometer</td>
                    <td class="px-0 pr-1">{{dialogRecord.record.vehicleOdometer}}</td>
                  </tr>
                  <tr>
                    <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                      <strong>Payment Info</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Type</td>
                    <td class="px-0 pr-1">
                      {{dialogRecord.record.paymentType}}
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Amount</td>
                    <td class="px-0 pr-1">
                      {{dialogRecord.record.paymentAmount}}
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Paid</td>
                    <td class="px-0 pr-1">
                      {{dialogRecord.record.paymentReceived ? 'Yes' : 'No'}}
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                      <strong>Request Info</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Create Date</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.createDate">{{dialogRecord.record.createDate.toDate() | moment("MMMM Do YYYY, h:mm:ss a")}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Update Date</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.updateDate">{{dialogRecord.record.updateDate.toDate() | moment("MMMM Do YYYY, h:mm:ss a")}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">JotForm Date</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.jotFormSubmissionDate">{{dialogRecord.record.jotFormSubmissionDate.toDate() | moment("MMMM Do YYYY, h:mm:ss a")}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">JotForm ID</td>
                    <td class="px-0 pr-1">
                      <a
                        :href="'https://www.jotform.com/inbox/'+dialogRecord.record.jotFormFormId + '/' + dialogRecord.record.jotFormSubmissionId"
                        target="_blank"
                      >
                        {{dialogRecord.record.jotFormSubmissionId}}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                      <strong>Status Info</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Status</td>
                    <td class="px-0 pr-1">
                      {{dialogRecord.record.status}}
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Status Date</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.statusDate">{{dialogRecord.record.statusDate.toDate() | moment("MMMM Do YYYY, h:mm:ss a")}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Processing Date</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.processingDate">{{dialogRecord.record.processingDate.toDate() | moment("MMMM Do YYYY, h:mm:ss a")}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Refunded Date</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.refundedDate">{{dialogRecord.record.refundedDate.toDate() | moment("MMMM Do YYYY, h:mm:ss a")}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Invoice ID</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.invoiceId">{{dialogRecord.record.invoiceId}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Batch Number</td>
                    <td class="px-0 pr-1">
                      {{dialogRecord.record.batchNumber}}
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Title Number</td>
                    <td class="px-0 pr-1">
                      {{dialogRecord.record.issuedTitleNumber}}
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pl-1 mt-8 grey lighten-3 py-1" colspan="2">
                      <strong>Shipping Info</strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Tracking Number</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.trackingNumberObj">
                        {{dialogRecord.record.trackingNumberObj.carrier}}:
                        <a :href="dialogRecord.record.trackingNumberObj.url" target="_blank">{{dialogRecord.record.trackingNumberObj.trackingNumber}}</a>
                      </span>
                      <span v-else>{{dialogRecord.record.trackingNumber}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-0 pr-1 pl-1" width="120">Ship Date</td>
                    <td class="px-0 pr-1">
                      <span v-if="dialogRecord.record.shipDate">{{dialogRecord.record.shipDate.toDate() | moment("MMMM Do YYYY, h:mm:ss a")}}</span>
                    </td>
                  </tr>
                </tbody>
            </v-simple-table>
            <v-textarea
              v-model="dialogRecord.record.notes"
              label="Notes"
              filled
              rows="2"
              class="mt-5"
            ></v-textarea>
            <v-btn
              color="primary"
              :disabled="saving"
              :loading="saving"
              @click="saveNotes()"
              >Save Notes</v-btn
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogRecord.show = false;"
              >Close</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTracking.show" max-width="600px">
      <v-card v-if="selected">
        <v-card-title primary-title>
          Add Tracking Information
        </v-card-title>
        <v-card-text>
          Provide a tracking number for <strong>{{selected.length}} title request record(s)</strong>.
          <v-text-field
            v-model="trackingNumber"
            class="mt-5 mb-0"
            outlined
            label="Tracking Number"
            dense
          ></v-text-field>
          <div class="mb-5">
            Need a USPS shipping label?
            <v-btn
              rounded
              outlined
              text
              x-small
              class="ml-2"
              @click="createLabel"
              :loading="saving"
              :disabled="saving"
              color="primary"
              >
                <v-icon small class="mr-1" color="primary">mdi-package-variant-closed</v-icon>
                Create Label
              </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="closeTrackingDialog"
            >Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="addTracking"
            :loading="saving"
            :disabled="saving"
          >
            Save
          </v-btn>
          <v-btn
            color="primary"
            @click="addTracking(true)"
            :loading="saving"
            :disabled="saving"
            >
            Save & Send Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPaid.show" max-width="600px">
      <v-card v-if="selected">
        <v-card-title primary-title>
          Update Paid
        </v-card-title>
        <v-card-text>
          Select the appropriate button below to update <strong>{{selected.length}} title request record(s)</strong> payment status.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogPaid.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="success"
            @click="updatePaid(true)"
            :loading="saving"
            :disabled="saving"
          >
            Paid
          </v-btn>
          <v-btn
            color="error"
            @click="updatePaid()"
            :loading="saving"
            :disabled="saving"
          >
            Not Paid
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mb-2">
      <v-col lg="3" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="warning fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-2 warning--text">
                      <h5 class="text-truncate text-uppercase">In Progress</h5>
                      <h1>{{processingCount}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="warning fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-2 warning--text">
                      <h5 class="text-truncate text-uppercase">> 30 Days</h5>
                      <h1>{{delayedCount}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="success fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-2 success--text">
                      <h5 class="text-truncate text-uppercase">Completed</h5>
                      <h1>{{completedCount}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
          <v-card>
              <v-row class="no-gutters">
                  <div class="col-auto">
                      <div class="error fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-2 error--text">
                      <h5 class="text-truncate text-uppercase">Denied</h5>
                      <h1>{{deniedCount}}</h1>
                  </div>
              </v-row>
          </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="pb-4">
        <v-card>
          <v-card-title
            class="ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-1">Title Requests</span>
            <vue-json-to-csv :json-data="csv" :csv-title="'title-requests-'+new Date().getTime()">
              <v-btn class="ml-4" color="white" small dark outlined
                ><v-icon small class="mr-1">download</v-icon> Export</v-btn
              >
            </vue-json-to-csv>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-row class="mb-1">
                    <v-col cols="12" sm="12" class="py-0">
                      <v-radio-group
                        v-model="filters.radio"
                        @change="filterData"
                        row
                      >
                        <v-radio
                          label="All"
                          value="All"
                        ></v-radio>
                        <v-radio
                          label="Paid No Status"
                          value="Paid No Status"
                        ></v-radio>
                        <v-radio
                          label="> 30 Days"
                          value="> 30 Days"
                        ></v-radio>
                        <v-radio
                          label="Processing"
                          value="Processing"
                        ></v-radio>
                        <v-radio
                          label="Refunded"
                          value="Refunded"
                        ></v-radio>
                        <v-radio
                          label="N/A"
                          value="N/A"
                        ></v-radio>
                        <v-radio
                          label="Archived"
                          value="Archived"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-alert
                    dense
                    color="grey lighten-5"
                    icon="library_add_check"
                    border="left"
                    v-if="selected.length"
                  >
                    {{ this.selected.length }} row(s) selected. &nbsp;&nbsp;
                    <v-btn outlined text small @click="clearSelections"
                      ><v-icon small class="mr-2" color="primary">mdi-checkbox-multiple-blank-outline</v-icon>
                      Clear</v-btn
                    >
                    <v-btn outlined text small class="ml-4" @click="openTrackingDialog"
                      ><v-icon small class="mr-2" color="primary">mdi-truck</v-icon>
                      Add Tracking</v-btn
                    >
                    <v-btn outlined text small class="ml-4" @click="openPaidDialog"
                      ><v-icon small class="mr-2" color="primary">mdi-cash</v-icon>
                      Update Paid</v-btn
                    >
                  </v-alert>
                  <v-data-table
                    :headers="headers"
                    :items="titles"
                    :search="search"
                    :loading="loading"
                    item-key="key"
                    v-model="selected"
                    show-select
                    :items-per-page="25"
                    :footer-props="{
                      itemsPerPageOptions: [25, 50, 100],
                    }"
                    dense
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        outlined
                        label="Search for title request"
                        dense
                      ></v-text-field>
                    </template>
                    <template v-slot:item.mostRecentDate="{ item }">
                      {{ item.mostRecentDate.toDate() | moment("M/D/YY") }}
                    </template>
                    <template v-slot:item.name="{ item }">
                      {{ item.name | truncate(18) }}
                    </template>
                    <template v-slot:item.vehicleVin="{ item }">
                      {{item.vehicleVin}}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="item.notes"
                            small
                            class="ml-1"
                            style="cursor: pointer;"
                          >
                            description
                          </v-icon>
                        </template>
                        <span>{{item.notes}}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="item.vehicleVinAlert"
                            small
                            color="error"
                            class="ml-1"
                            style="cursor: pointer;"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span>Duplicate VIN</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            color="success"
                            v-on="on"
                            v-if="item.trackingNumber"
                            small
                            class="ml-1"
                            style="cursor: pointer;"
                          >
                            mdi-truck
                          </v-icon>
                        </template>
                        <span>{{item.trackingNumber}}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.returnBos="{ item }">
                      <v-chip
                        :color="item.returnBos ? 'success' : 'error'"
                        class="text-capitalize"
                        small
                      >
                        {{item.returnBos ? 'Yes' : 'No'}}
                      </v-chip>
                    </template>
                    <template v-slot:item.paymentReceived="{ item }">
                      <v-chip
                        :color="item.paymentReceived ? 'success' : 'error'"
                        class="text-capitalize"
                        small
                      >
                        {{item.paymentType || "N/A"}}
                      </v-chip>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-chip
                        :color="item.status === 'processing' ? 'warning' : item.status === 'completed' ? 'success' : 'error'"
                        class="text-capitalize"
                        small
                        v-show="item.status"
                      >
                        {{item.status}}
                      </v-chip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-if="item.delayedAlert"
                            small
                            class="ml-1"
                            style="cursor: pointer;"
                            color="warning"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span>> 30 Days</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn
                        class="mr-1"
                        elevation="2"
                        x-small
                        compact
                        color="primary"
                        @click="toggleDialogUpload(item)"
                      >
                        <v-icon
                        small
                        >
                          mdi-upload
                        </v-icon>
                      </v-btn>
                      <v-btn
                        class="mr-1"
                        elevation="2"
                        x-small
                        compact
                        color="primary"
                        @click="toggleDialogRecord(item)"
                      >
                        <v-icon
                        small
                        >
                          mdi-card-account-details
                        </v-icon>
                      </v-btn>
                      <v-menu
                        v-model="item.menu"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        :max-width="300"
                        offset-x
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation="2"
                            x-small
                            compact
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            @click="storeOriginalValues(item)"
                          >
                            <v-icon
                            small
                            >
                              mdi-wrench
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content style="padding-top:0;padding-bottom:0;">
                                <v-list-item-title>{{item.firstName}} {{item.lastName}}</v-list-item-title>
                                <v-list-item-subtitle>{{item.vehicleYear}} {{item.vehicleMake}} {{item.vehicleModel}}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-divider></v-divider>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content style="display: flex; flex-wrap: wrap;justify-content: space-between;">
                                <v-btn
                                  color="primary"
                                  text
                                  outlined
                                  small
                                  v-show="item.filenameBos"
                                  :disabled="downloading === 'bos'"
                                  :loading="downloading === 'bos'"
                                  @click="downloadBos(item)"
                                  style="flex: 1 0;"
                                  class="mr-1"
                                >
                                  BOS
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  outlined
                                  small
                                  v-show="item.filenameVin"
                                  :disabled="downloading === 'vin'"
                                  :loading="downloading === 'vin'"
                                  @click="downloadVin(item)"
                                  style="flex: 1 0;"
                                  class="mr-1"
                                >
                                  PIC
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  outlined
                                  small
                                  @click="generatePdf('coa', item)"
                                  @loading="generatingPdf"
                                  :loading="generatingPdf"
                                  :disabled="generatingPdf"
                                  style="flex: 1 0;"
                                  class="mr-1"
                                >
                                  COA
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  outlined
                                  small
                                  @click="generatePdf('toa', item)"
                                  :loading="generatingPdf"
                                  :disabled="generatingPdf"
                                  style="flex: 1 0;"
                                  class="mr-1"
                                >
                                  TOA
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  outlined
                                  small
                                  @click="generatePdf('vin-verification', item)"
                                  @loading="generatingPdf"
                                  :loading="generatingPdf"
                                  :disabled="generatingPdf"
                                  style="flex: 1 0;margin-bottom: 2px;"
                                >
                                  VVF
                                </v-btn>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-divider></v-divider>
                          <v-list v-if="originalItemValues[item.id]">
                            <v-list-item>
                              <v-list-item-action>
                                <v-switch
                                  v-model="originalItemValues[item.id].returnBos"
                                  color="success"
                                ></v-switch>
                              </v-list-item-action>
                              <v-list-item-title>Return BOS</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-action>
                                <v-switch
                                  v-model="originalItemValues[item.id].paymentReceived"
                                  color="success"
                                ></v-switch>
                              </v-list-item-action>
                              <v-list-item-title>Payment Received</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-text-field
                                v-model="originalItemValues[item.id].vehicleVin"
                                class="mt-1"
                                outlined
                                label="Vehicle VIN"
                                dense
                              ></v-text-field>
                            </v-list-item>
                            <v-list-item>
                              <v-select
                                dense
                                v-model="originalItemValues[item.id].status"
                                :items="['', 'processing', 'completed', 'denied', 'refunded']"
                                label="Status"
                                outlined
                                hint="*Processing status auto-sends email"
                                persistent-hint
                              ></v-select>
                            </v-list-item>
                            <v-list-item>
                              <v-text-field
                                v-model="originalItemValues[item.id].batchNumber"
                                class="mt-1"
                                outlined
                                label="Batch Number"
                                dense
                              ></v-text-field>
                            </v-list-item>
                            <v-list-item>
                              <v-text-field
                                v-model="originalItemValues[item.id].issuedTitleNumber"
                                outlined
                                label="Title Number"
                                dense
                              ></v-text-field>
                            </v-list-item>
                          </v-list>

                          <v-card-actions>
                            <v-btn
                              icon
                              small
                              color="error"
                              :loading="saving"
                              :disabled="saving"
                              @click="archiveRecord(item)"
                            >
                              <v-icon small>inventory_2</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="error"
                              @click="item.menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="primary"
                              :loading="saving"
                              :disabled="saving"
                              @click="updateRecord(item)"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </template>
                    <template slot="no-data">
                      <span class="grey--text text--darken-3">
                        No records found.
                      </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
import fb from "@/main";
import { mapGetters } from "vuex";
import { uniq, sortBy } from "lodash";
import firebase from "firebase/app";
import VueJsonToCsv from 'vue-json-to-csv';
import UploadFile from "@/components/UploadFile.vue";
import { getTrackingNumberObj, getEmailSignature } from "@/helpers";

export default {
  name: "Home",
  components: {
    VueJsonToCsv,
    UploadFile
  },
  data() {
    return {
      search: "",
      dialogUpload: {
        show: false,
        record: {},
      },
      dialogRecord: {
        show: false,
        record: {}
      },
      dialogTracking: {
        show: false
      },
      dialogPaid: {
        show: false
      },
      filters: {
        radio: "All"
      },
      selected: [],
      originalItemValues: {},
      trackingNumber: "",
      downloading: false,
      saving: false,
      loading: true,
      generatingPdf: false,
      snack: false,
      snackColor: "",
      snackText: "",
      headers: [
        {
          text: "Date",
          value: "mostRecentDate"
        },
        {
          text: "Name",
          value: "name",
          width: "175"
        },
        {
          text: "Year",
          value: "vehicleYear"
        },
        {
          text: "Make",
          value: "vehicleMake"
        },
        {
          text: "Model",
          value: "vehicleModel"
        },
        {
          text: "VIN",
          value: "vehicleVin"
        },
        {
          text: "Price",
          value: "vehiclePrice"
        },
        {
          text: "Ret BOS",
          value: "returnBos"
        },
        {
          text: "Paid",
          value: "paymentReceived"
        },
        {
          text: "Status",
          value: "status"
        },
        { text: "",
          value: "edit",
          align: "end",
          width: "150"
        },
      ],
      originalTitles: [],
      titles: [],
      csv: [],
    };
  },
  async created() {
    this.getTitleRequests();
  },
  computed: {
    delayedCount: function() {
      return this.titles.filter((t) => t.delayedAlert).length;
    },
    processingCount: function() {
      return this.titles.filter((t) => t.status === 'processing').length;
    },
    completedCount: function() {
      return this.titles.filter((t) => t.status === 'completed').length;
    },
    deniedCount: function() {
      return this.titles.filter((t) => t.status === 'denied').length;
    },
    ...mapGetters(["user"]),
  },
  methods: {
    async getTitleRequests() {
      this.loading = true;
      const titleRequestsResponse = await fb.db.collection("titleRequests").orderBy('createDate', 'desc').get();
      const originalTitles = [];
      titleRequestsResponse.forEach((t) => {
        const title = t.data();
        originalTitles.push({
          ...title,
          name: `${title.firstName} ${title.lastName}`,
          id: t.id
        });
      });
      originalTitles.forEach((t) => {
        t.key = `${t.zip}-${t.vehicleVin}`;
        t.vehicleVinAlert = originalTitles.find((tt) => {return tt.vehicleVin && tt.vehicleVin === t.vehicleVin && tt.zip !== t.zip;});
        t.delayedAlert = t.status === 'processing' && t.statusDate && ((new Date().getTime() - t.statusDate.toDate().getTime()) / (1000 * 3600 * 24)) >= 30;
        t.mostRecentDate = t.jotFormSubmissionDate || t.updateDate || t.createDate;
        t.trackingNumberObj = getTrackingNumberObj(t.trackingNumber);
      })
      this.originalTitles = sortBy(originalTitles, (n) => { return n.mostRecentDate }).reverse();
      this.csv = this.originalTitles.map((d) => {
        return {
          "date": new Date(d.mostRecentDate.seconds * 1000).toLocaleDateString(),
          "name": d.name,
          "email": d.email,
          "phone": d.phone,
          "status": d.status || '',
          "vehicle year": d.vehicleYear,
          "vehicle make": d.vehicleMake,
          "vehicle model": d.vehicleModel,
          "vehicle type": d.vehicleType,
          "vehicle price": d.vehiclePrice,
          "payment type": d.paymentType || '',
          "payment received": !!d.paymentReceived,
          "payment amount": d.paymentAmount || '',
          "jotform submission id": d.jotFormSubmissionId || '',
          "notes": d.notes || '',
        }
      })
      this.filterData();
      this.loading = false;
    },
    filterData() {
      this.selected = [];
      this.search = "";
      if (this.filters.radio === 'All') {
        this.titles = this.originalTitles.filter((d) => {return !d.archived});
      }
      if (this.filters.radio === 'Paid No Status') {
        this.titles = this.originalTitles.filter((d) => {return d.paymentReceived && !d.status && !d.archived})
      }
      if (this.filters.radio === '> 30 Days') {
        this.titles = this.originalTitles.filter((d) => {return d.delayedAlert && !d.archived})
      }
      if (this.filters.radio === 'Processing') {
        this.titles = this.originalTitles.filter((d) => {return d.status === "processing" && !d.archived});
      }
      if (this.filters.radio === 'Refunded') {
        this.titles = this.originalTitles.filter((d) => {return d.status === "refunded" && !d.archived})
      }
      if (this.filters.radio === 'N/A') {
        this.titles = this.originalTitles.filter((d) => {return !d.paymentType && !d.archived})
      }
      if (this.filters.radio === 'Archived') {
        this.titles = this.originalTitles.filter((d) => {return d.archived})
      }
    },
    clearSelections() {
      this.selected = [];
    },
    async generatePdf(filename, item) {
      let fields = {};
      if (filename === 'coa') {
        const dateOfPurchase = moment(item.mostRecentDate.toDate()).format(" MM   DD       YY");
        fields = {
          "Name": "Saints Engine and Machine",
          "Phone": "614-886-9544",
          "Email": "joe@saintsengine.com",
          "City": "Dunlap",
          "Street Address": "1424 Main Street",
          "State": "TN",
          "Zip": "37327",
          "Vehicle Identification Number": item.vehicleVin,
          "Make": item.vehicleMake,
          "Year Model": item.vehicleYear,
          "Model": item.vehicleModel,
          "Body Type": item.vehicleType,
          "Seller Information": item.firstName + " " + item.lastName,
          "Date of purchase": dateOfPurchase,
          "Date": moment().format("MM/DD/YYYY"),
          "Street Address 1": item.streetAddress,
          "City 2": item.city,
          "State 2": item.state,
          "Zip 2": item.zip,
          // "Phone 2": item.phone,
          "Purchase Price": item.vehiclePriceReturnBos ? item.vehiclePriceReturnBos : item.vehiclePrice,
          "Text7": "None Provided."
        };
      }
      if (filename === 'toa') {
        fields = {
          "Owner Name": "Saints Engine and Machine",
          "Email": "joe@saintsengine.com",
          "Phone": "614-886-9544",
          "Vehicle Year": item.vehicleYear,
          "Vehicle Make": item.vehicleMake,
          "Vehicle Model": item.vehicleModel,
          "Vehicle Identification Number VIN": item.vehicleVin,
          // "USDOT"
          // "fill_14"
          // "Lessee Name"
          "Date": moment().format("MM/DD/YYYY"),
          // "Vehicle Services approval by"
          // "Date_2"
          "Mailing street": "P.O. Box 17402",
          "Physical street": "1424 Main Street",
          "Physical st": "TN",
          "Physical zip": "37327",
          "Physical city": "Dunlap",
          "mailing city": "Chattanooga",
          "mailing st":  "TN",
          "mailing zip": "37415"
          // "Text1"
          // "IRP Number"
          // "Repo"
          // "Insurance"
          // "Dealer"
          // "non profit"
          // "confiscation"
          // "Check Box8"
          // "IRP REG"
          // "if yes"
          // "not required"
          // "Check Box1"
        };
      }
      if (filename === 'vin-verification') {
        fields = {
          "Date": moment().format("MM/DD/YYYY"),
          "Year": item.vehicleYear,
          "Make": item.vehicleMake,
          "Model": item.vehicleModel,
          "VIN": item.vehicleVin,
          "Dealer Number": "22153",
          "Printed Name": "Joe Casola"
        }
      }
      this.generatingPdf = true;
      const generatePdfCall = fb.functions.httpsCallable("generatePdf");
      const response = await generatePdfCall({fields: fields, filename: filename});
      this.generatingPdf = false;
      if (response.data.base64) {
        // Convert base64 to a Blob
        const byteCharacters = atob(response.data.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/pdf'});

        // Create a URL for the Blob and open it in a new window
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
      }
    },
    async downloadBos(item) {
      if (item.filenameBos) {
        try {
          this.downloading = 'bos';
          const getImage = fb.storage.ref(`jotForm/${item.jotFormFormId}/${item.jotFormSubmissionId}/${item.filenameBos}`);
          const url = await getImage.getDownloadURL();
          window.open(url, '_blank');
          this.downloading = false;
        } catch (e) {
          alert(`Error: ${e.message}`);
          this.downloading = false;
        }
      }
    },
    async downloadVin(item) {
      if (item.filenameVin) {
        try {
          this.downloading = 'vin';
          const getImage = fb.storage.ref(`jotForm/${item.jotFormFormId}/${item.jotFormSubmissionId}/${item.filenameVin}`);
          const imageUrl = await getImage.getDownloadURL();

           // Fetch the image as a blob
          const response = await fetch(imageUrl);
          const blob = await response.blob();

          // Create a URL for the blob
          const blobUrl = window.URL.createObjectURL(blob);

          // Create a temporary link to trigger the download
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = 'downloaded-image.jpeg'; // Specify the filename to download as

          // Append to the document and trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up by revoking the blob URL and removing the link
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(link);
          this.downloading = false;
        } catch (e) {
          alert(`Error: ${e.message}`);
          this.downloading = false;
        }
      }
    },
    storeOriginalValues(item) {
      // Store original values to compare later
      this.originalItemValues[item.id] = {
        vehicleVin: item.vehicleVin,
        status: item.status,
        returnBos: item.returnBos,
        paymentReceived: item.paymentReceived,
        batchNumber: item.batchNumber,
        issuedTitleNumber: item.issuedTitleNumber
      };
    },
    async archiveRecord(item) {
      if (item.archived) {
        if (confirm("Are you sure you want to unarchive this record?") == true) {
          this.saving = true;
          await fb.db.collection('titleRequests').doc(item.id).update({
            archived: false
          });
          this.saving = false;
          this.snack = true;
          this.snackText = `Success`;
          this.snackColor = "success";
          item.archived = false;
          item.menu = false;
          this.filterData();
        }
      } else {
        if (confirm("Are you sure you want to archive this record?") == true) {
          this.saving = true;
          await fb.db.collection('titleRequests').doc(item.id).update({
            archived: true
          });
          this.saving = false;
          this.snack = true;
          this.snackText = `Success`;
          this.snackColor = "success";
          item.archived = true;
          item.menu = false;
          this.filterData();
        }
      }
    },
    async updateRecord(item){
      const obj = {};
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      let html = '';
      let subject = '';

      if (this.originalItemValues[item.id].vehicleVin !== item.vehicleVin) {
        const newVehicleVin = this.originalItemValues[item.id].vehicleVin.replace(/[^a-z0-9]/gi, '').toUpperCase();
        const comboFound = this.originalTitles.find((ot) => ot.vehicleVin == newVehicleVin && ot.zip == item.zip)
        if (comboFound) {
          alert("Sorry, this VIN + ZIP already exists.");
          return;
        }
        else if (!newVehicleVin.length) {
          alert("Sorry, a VIN is required.");
          return;
        } else {
          obj.vehicleVin = newVehicleVin;
          item.vehicleVin = newVehicleVin;
        }
      }
      if (this.originalItemValues[item.id].returnBos !== item.returnBos) {
        obj.returnBos = this.originalItemValues[item.id].returnBos;
        item.returnBos = this.originalItemValues[item.id].returnBos;
      }
      if (this.originalItemValues[item.id].paymentReceived !== item.paymentReceived) {
        const paymentReceived = this.originalItemValues[item.id].paymentReceived;
        obj.paymentReceived = paymentReceived;
        item.paymentReceived = paymentReceived;

        if (paymentReceived) {
          subject = `Payment for ${item.vehicleVin} received`;
          html = ''; // reset
          html += `<p>Hi ${item.firstName}!</p>`;
          html += `<p>We are pleased to inform you that we received your payment for your ${item.vehicleYear} ${item.vehicleMake} (${item.vehicleVin}) title application which is now in the queue for processing. You will receive another email once your title application moves to the "processing" stage.</p>`;
          html += `<p>Should you have any questions or need further assistance, please do not hesitate to contact us.</p>`;
          html += `<p>Have a great day!</p>`;
          html += getEmailSignature();
        }
      }
      if (this.originalItemValues[item.id].status !== item.status) {
        const status = this.originalItemValues[item.id].status;
        obj.status = status;
        item.status = status;
        obj.statusDate = timestamp;

        if (status === 'processing') {
          obj.processingDate = timestamp;

          subject = `Title paperwork for ${item.vehicleVin} processing`;
          html = ''; // reset
          html += `<p>Hi ${item.firstName}!</p>`;
          html += `<p>Thank you for submitting your paperwork for your ${item.vehicleYear} ${item.vehicleMake} (${item.vehicleVin}). We have started processing it, and currently, our turnaround time is approximately 4 weeks.</p>`;
          html += `<p>Please note that in some cases, processing times may be longer if the documents are being reviewed at the state level. We appreciate your patience, and if there are any unexpected delays, we will notify you promptly.</p>`;
          html += `<p>If you have any questions or concerns, please don't hesitate to reach out to us via phone or email. Otherwise, I will send you a follow-up email with a tracking number once the title is ready and on its way to you.</p>`;
          html += getEmailSignature();
        }
        else if (status === 'refunded') {
          obj.refundedDate = timestamp;

          subject = `Title paperwork for ${item.vehicleVin} refunded`;
          html = ''; // reset
          html += `<p>Hi ${item.firstName},</p>`;
          html += `<p>We are temporarily unable process the title paperwork for your ${item.vehicleYear} ${item.vehicleMake} (${item.vehicleVin}) so it will be ${item.paymentReceived ? 'refunded' : 'returned'}.</p>`;
          html += `<p>If you have any questions, please don't hesitate to reach out to us via phone or email.</p>`;
          html += getEmailSignature();
        } else {
          item.delayedAlert = false;
        }
      }
      if (this.originalItemValues[item.id].batchNumber !== item.batchNumber) {
        obj.batchNumber = this.originalItemValues[item.id].batchNumber;
        item.batchNumber = this.originalItemValues[item.id].batchNumber;
      }
      if (this.originalItemValues[item.id].issuedTitleNumber !== item.issuedTitleNumber) {
        obj.issuedTitleNumber = this.originalItemValues[item.id].issuedTitleNumber;
        item.issuedTitleNumber = this.originalItemValues[item.id].issuedTitleNumber;
      }
      if (Object.keys(obj).length) {
        this.saving = true;
        await fb.db.collection('titleRequests').doc(item.id).update(obj);
        if (html) {
          await fb.db.collection("emails").add({
            to: item.email,
            message: {
              subject: subject,
              text: html
            }
          })
        }
        this.saving = false;
        this.snack = true;
        this.snackText = `Success`;
        this.snackColor = "success";
        item.menu = false;
      }
      else {
        alert("No updates were made.")
      }
    },
    toggleDialogUpload(item) {
      this.dialogUpload.record = item;
      this.dialogUpload.show = true;
    },
    toggleDialogRecord(item) {
      this.dialogRecord.record = item;
      this.dialogRecord.show = true;
    },
    async saveNotes() {
      this.saving = true;
      const notes = this.dialogRecord.record.notes || "";
      const docId = this.dialogRecord.record.id;
      await fb.db.collection('titleRequests').doc(docId).update({notes});
      this.saving = false;
      this.snack = true;
      this.snackText = `Success`;
      this.snackColor = "success";
      this.dialogRecord.show = false;
    },
    async createLabel() {
      try {
        const emails = uniq(this.selected.map((s) => s.email));
        if (emails.length === 1) {
          const record = (sortBy(this.selected, (n) => { return n.mostRecentDate }).reverse())[0];
          const obj = {
            name: record.name,
            streetAddress: record.streetAddress,
            city: record.city,
            state: record.state,
            zip: record.zip,
            phone: record.phone,
            shipDate: moment().format("YYYY-MM-DD")
          }
          this.saving = true;
          const createShippingLabelCall = fb.functions.httpsCallable("createShippingLabel");
          const { data: recordset } = await createShippingLabelCall(obj);
          this.trackingNumber = recordset.trackingNumber;

          const base64string = recordset.labelData;
          const url = 'data:application/pdf;base64,'+ base64string;
          let blobUrl;
          fetch(url).then(res => res.blob())
          .then(URL.createObjectURL)
          .then((ret) => {
            blobUrl = ret;
            window.open(blobUrl);
          });
          this.saving = false;
        }
      } catch (e) {
        alert(`Error: ${e.message}`);
        this.saving = false;
      }
    },
    async addTracking(sendEmail) {
      const trackingNumber = (this.trackingNumber || "").trim();
      const emails = uniq(this.selected.map((s) => s.email));
      const vins = uniq(this.selected.map((s) => s.vehicleVin));
      if (emails.length === 1 && trackingNumber.length) {
        this.saving = true;
        if (sendEmail) {
          const firstName = this.selected[0].firstName;
          const email = this.selected[0].email;
          const trackingNumberObj = getTrackingNumberObj(trackingNumber);
          let html = '';
          html += `<p>Hi ${firstName}!</p>`;
          html += `<p>Your title(s) is on its way!</p>`;
          html += `<p>${vins.join('<br>')}</p>`
          html += `<p>Here is the tracking information for your title(s) and, if requested, the return bill(s) of sale. If you notice that not all of your titles are listed, please don't worry. It simply means they are undergoing further verification at the state level and will be issued to you shortly.</p>`;
          html += `<p>If you have any concerns or need any assistance, please don't hesitate to let us know.</p>`;
          if (trackingNumberObj) {
            html += `<p>${trackingNumberObj.carrier}: <a href="${trackingNumberObj.url}">${trackingNumberObj.trackingNumber}</a></p>`;
          }
          html += `<p>We would greatly appreciate it if you could take a moment to share your experience with us by leaving a Google review. Your feedback is valuable to us!</p>`;
          html += `<p><a href="http://search.google.com/local/writereview?placeid=ChIJZ1iQn6RfYIgRbzfN_kG6pPM">Click Here to Leave a Google Review</a></p>`;
          html += `<p>Thank you once again for giving us the opportunity to assist you with the titling process.</p>`;
          html += getEmailSignature();

          await fb.db.collection("emails").add({
            to: email,
            message: {
              subject: `Tracking Info and Google Review`,
              text: html
            }
          })
        }
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        for (const record of this.selected) {
          await fb.db.collection('titleRequests').doc(record.id).update({
            trackingNumber,
            shipDate: timestamp
          });
        }
        // update UI
        const ids = this.selected.map((s) => s.id)
        this.titles.forEach((t) => {
          if (ids.includes(t.id)){
            t.trackingNumber = trackingNumber;
            t.trackingNumberObj = getTrackingNumberObj(t.trackingNumber);
          }
        });
        this.trackingNumber = "";
        this.dialogTracking.show = false;
        this.saving = false;
        this.snack = true;
        this.snackText = `Success`;
        this.snackColor = "success";
        this.dialogRecord.show = false;
      }
    },
    openTrackingDialog() {
        const emails = uniq(this.selected.map((s) => s.email));
        if (emails.length > 1) {
          alert("You can only add tracking to title requests of the same email.")
        }
        else if (emails.length === 1) {
          this.dialogTracking.show = true;
        }
    },
    closeTrackingDialog() {
      if (this.trackingNumber) {
        alert("You haven't saved the tracking number. Please save or remove it before hitting cancel.")
      } else {
        this.dialogTracking.show = false;
        this.trackingNumber = '';
      }
    },
    openPaidDialog() {
      const emails = uniq(this.selected.map((s) => s.email));
      if (emails.length > 1) {
       alert("You can only mark paid title requests of the same email.")
      }
      else if (emails.length === 1) {
        this.dialogPaid.show = true;
      }
    },
    async updatePaid(paid) {
      const emails = uniq(this.selected.map((s) => s.email));
      if (emails.length === 1) {
        this.saving = true;
        for (const record of this.selected) {
          await fb.db.collection('titleRequests').doc(record.id).update({
            paymentReceived: !!(paid)
          });
        }
        // update UI
        const ids = this.selected.map((s) => s.id)
        this.titles.forEach((t) => {
          if (ids.includes(t.id)){
            t.paymentReceived = !!(paid);
          }
        });
        this.dialogPaid.show = false;
        this.saving = false;
        this.snack = true;
        this.snackText = `Success`;
        this.snackColor = "success";
      }
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
      this.snack = true;
      this.snackText = `Copied`;
      this.snackColor = "success";
    }
  }
};
</script>